.testimonial .items{
    
    padding: 30px;
    transition: 0.5s;
}
.testimonial .img{
    position: relative;
}
.testimonial img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.testimonial i{
    position:  absolute;
    bottom: 0;
    left: 60px;
    background-color: #1eb2a6;
    color: white;
}
.testimonial .name{
    margin: 25px;
}
.testimonial .name h2{
    font-size: 20px;
    margin-bottom: 5px;
}
.testimonial .name span{
    color: #1eb2a6;
}
.testimonial p{
    margin-top: 20px;
    color: gray;
    transform-origin: 0.5s;
}
.testimonial .items:hover{
    background-color: #1eb2a6;
    color: #fff;
    cursor: pointer;
}
.testimonial .items:hover p,
.testimonial .items:hover span{
    color: #fff;
}
.testimonial .items:hover i{
    background-color: #fff;
    color: #1eb2a6;
}