.price .items{
    padding: 40px;
    text-align: center;
}
.price h1{
    font-size: 50px;
    margin: 20px 0;
    color: #1eb2a6;
}
.price h1 span{
    font-size: 20px;
    margin-right: 5px;
    color: #000;
}
.price p{
    color: gray;
    margin: 40px 0;
}
.faq .container {
    max-width: 70%;
    border-radius: 5px;
  }
  .faq .box {
    background-color: #fff;
    margin-bottom: 20px;
  }
  .faq button {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
  }
  .faq h2 {
    font-weight: 500;
  }
  .faq p {
    padding: 20px;
  }
  .faq .accordion:hover {
    background-color: #1eb2a6;
    color: #fff;
  }