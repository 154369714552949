.head {
    padding: 20px 0;
    color: #ffffff;
  }
  .logo h1 {
    font-size: 35px;
    font-weight: 900;
  }
  .head .icon {
    margin-left: 10px;
  }
  /*header*/
  header{
    background-color: rgba(255,255,255,0.2);
    margin: 0 30px;
    position: relative;
  }
  header ul{
    padding: 30px 20px;
  }
header li{
    margin-right: 40px;
}
header ul li a{
    color: #ffffff;
    font-weight: 500;
    transition: 0.5s;
}
header ul li a:hover{
    color: #1eb2a6;
}
.start{
    background-color: #1eb2a6;
    padding: 30px 70px;
    color: #ffffff;
    clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}

.toggle{
    display: none;
}
@media screen and (max-width: 768px) {
    .start{
        clip-path: none;
    }
    header{
        margin: 0;
        background-color: #1eb2a6;
        position: relative;
    }
    header ul .flexSB{
        display: none;
    }
    header ul li{
        margin-bottom: 20px;
    }
    .toggle{
        display: block;
        background-color: none;
        color: #ffffff;
        font-size: 30px;
        position: absolute;
        right: 50px;
        top: 20px;
    }
    .mobile-nav {
        position: absolute;
        top: 7vh;
        left: 0;
        width: 100%;
        background-color: #1eb2a6;
      }
}