.blog img{
    width: 100%;
    height: 100%;
}
.blog .text{
padding: 40px 30px;
}
.blog h1{
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    margin: 20px 0;
    transition: 0.5s;

}
.blog h1:hover{
    color: #1eb2a6;
    cursor: pointer;
}
.blog span{
    text-transform: uppercase;
    font-weight: 500;
    color: #1eb2a6;
}
.blog span label{
    margin-left: 10px;
    color: gray;
    font-size: 14px;
}
.blog span:nth-child(1) label{
    color: #1eb2a6;
}
.blog p{
    color: gray;
}