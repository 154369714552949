
.hero{
    background-image: url('../../../../public/images/home.jpg');
    background-size: cover;
    background-attachment: fixed;
    position:  absolute;
    top: 0;left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    padding-top: 20%;
    padding-right: 50px;
    color: #ffffff;
}
.hero #heading h3{
    color: #ffffff;
}
.hero #heading{
    text-align: left;
    margin: 0;
    padding: 0;
}
@media screen and (max-width: 768px) {
    .margin {
      height: 40vh;
    }
    .hero{
        background-position: right;
        padding-top: 50%;
        height: 80vh;
    }
    .hero .row{
        width: 100%;
    }
 }